import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./mocks";

import { Provider } from "react-redux";
import { store, persistedStore } from "./redux/store/index";

import axios from "axios";
import { PersistGate } from "redux-persist/integration/react";

let ISDEV = true;
let DEV_URL = "https://api.haelf.com";
let LOCAL_URL = "http://localhost:5000";

axios.defaults.baseURL = ISDEV ? DEV_URL : LOCAL_URL;

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistedStore}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
