// import axios from "../utils/axios";
import axios from "axios";

export function signIn(credentials) {
  console.log("Signin", credentials);
  return new Promise((resolve, reject) => {
    axios
      .post("api/doctor/login", credentials)
      .then((response) => {
        console.log("api response", response);
        resolve(response.data);
        // if (response?.data?.Status === 200) {
        //   console.log("login response", response);
        //   resolve(response.data);
        // }
        // reject(response?.data);
      })
      .catch((error) => {
        console.log("login error", error);
        reject(error);
      });
  });
}

export function signUp(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/auth/sign-up", credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function resetPassword(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/auth/reset-password", credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
